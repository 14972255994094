<template>
  <div class="table">
    <transition name="fade-a" mode="out-in">
      <div v-if="phoneStore.contactLoading" class="table__row-group--empty">
        <Loader></Loader>
      </div>
      <div v-else-if="phoneStore.gg_contactList.length" class="prime-table">
        <CustomDatatable
          @open-row-modal="openRowModal"
          v-model:rows="phoneStore.gg_contactList"
          :headers="phoneStore.headers"
          @delete-contact="deleteContact"
          @update:rows="updateRows"
          @sort:rows="customSort"
        />
      </div>
      <div v-else class="table__row-group--empty">
        <div class="table__text--empty">
          {{ phoneStore.emptyMessage || 'Start by importing a list of contacts' }}
        </div>
      </div>
    </transition>
  </div>
  <row-modal
    :is-modal-open="phoneStore.selectedRowId !== null"
    :row-data="selectedRowData"
    @close-modal="closeModal"
    :headers="phoneStore.headers"
    @saveNewInfo="saveNewInfo"
    @saveNote="saveNote"
    @deleteNote="deleteNote"
    @pinNote="pinNote"
    @saveLog="saveLog"
    @saveCallDisposition="saveCallDisposition"
    @createNewNote="createNewNote"
    @saveNewTask="saveNewTask"
    @updateTask="updateTask"
    @toggleMeetingBooked="toggleMeetingBooked"
    :meeting-booked="phoneStore.getMeetingBooked(selectedRowData?.id)"
    @snoozed="($events) => updateSnoozeUntil(selectedRowData?.id, $events)"
    @update:doNotCall="($events) => updateDoNotCall(selectedRowData?.id, $events)"
    callable
  />
</template>

<script setup>
import RowModal from './RowModal.vue'

import { ref, onMounted, onDeactivated, computed } from 'vue'

import { dialStatusToCall, usePhoneStore } from '@/stores/phone'
import CustomDatatable from './Datatable--homemade.vue'
import Loader from './Loader.vue'

const phoneStore = usePhoneStore()

function updateSnoozeUntil(contactId, date) {
  const contact = phoneStore.gg_contactList.find((contact) => contact.id === contactId)
  contact.snoozeUntil = date
  phoneStore.checkPhoneNumber(contact)
  phoneStore.sortByStatus()
}

function updateDoNotCall(contactId, value) {
  const contact = phoneStore.gg_contactList.find((contact) => contact.id === contactId)
  contact.doNotCall = value
  phoneStore.checkPhoneNumber(contact)
  phoneStore.sortByStatus()
}

function customSort() {
  phoneStore.customSortMethod()
}

function updateRows(rows) {
  phoneStore.gg_contactList = rows
}

function openRowModal(row) {
  phoneStore.openRowModal(row.data)
}

function deleteContact(row) {
  phoneStore.deleteContact(row)
}

const selectedRowData = computed(() => {
  return phoneStore.gg_contactList.find((item) => item.id === phoneStore.selectedRowId)
})

function saveNewInfo(rowData, header, value) {
  phoneStore.updateField(rowData, header, value)
}

function saveNote(rowId, noteId, body) {
  phoneStore.updateNote(rowId, noteId, body)
}

function deleteNote(rowId, noteId) {
  phoneStore.deleteNote(rowId, noteId)
}

function pinNote(rowId, noteId) {
  phoneStore.pinNote(rowId, noteId)
}

function saveLog(rowId, logId, body) {
  phoneStore.updateLog(rowId, logId, body)
}

function saveCallDisposition(rowId, logId, callDisposition) {
  phoneStore.updateCallDisposition(rowId, logId, callDisposition)
}

async function createNewNote(rowId) {
  await phoneStore.createNewNote(rowId)
  await phoneStore.fetchNotes(rowId)
}

async function saveNewTask(rowId, task) {
  await phoneStore.saveNewTask(rowId, task)
  await phoneStore.fetchTasks(rowId)
}

async function updateTask(row, taskId, taskField, taskValue) {
  await phoneStore.updateTask(row.id, taskId, taskField, taskValue)
}

async function toggleMeetingBooked(rowId) {
  await phoneStore.toggleMeetingBooked(rowId)
  if (phoneStore.getMeetingBooked(rowId)) {
    const count = 200,
      defaults = {
        origin: { y: 0.7 }
      }

    function fire(particleRatio, opts) {
      confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio)
        })
      )
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55
    })

    fire(0.2, {
      spread: 60
    })

    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    })

    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    })

    fire(0.1, {
      spread: 120,
      startVelocity: 45
    })
  }
}

onMounted(() => {
  addEventListener('keydown', handleShortcut)
})

onDeactivated(() => {
  removeEventListener('keydown', handleShortcut)
})

const shortcuts = {
  'Command+p': phoneStore.hangupAndResume,
  'Ctrl+p': phoneStore.hangupAndResume,
  Escape: closeModal
}

function handleShortcut(event) {
  const keyCombo = `${event.metaKey ? 'Command+' : event.ctrlKey ? 'Ctrl+' : ''}${event.key}`
  const action = shortcuts[keyCombo]

  if (action && typeof action === 'function') {
    event.preventDefault()
    action()
  }
}

function closeModal() {
  if (!phoneStore.gg_activeCall.isActive && !phoneStore.dropdownInModalOpen) {
    phoneStore.selectedRowId = null
  }
}
</script>

<style scoped lang="scss">
.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
  // overflow: auto;
  white-space: nowrap;
  align-items: start;

  border-right: 1px solid #eaecf0;
  border-left: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
  border-radius: 0 0 8px 8px;

  min-height: 0;
  flex: 1 1 auto;

  .prime-table {
    min-height: 0;
    flex: 1 1 auto;
    width: 100%;
  }
}

.table__row-group--empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.table__text--empty {
  color: #5c5c5c;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
}
.fade-a-enter-active,
.fade-a-leave-active {
  transition: opacity 0.05s ease;
}

.fade-a-enter-from,
.fade-a-leave-to {
  opacity: 0;
}
</style>
