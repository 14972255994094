<template>
  <div class="session-details">
    <div class="header-section">
      <div class="header__button" @click="reopenSession">
        <PlayIcon class="button__icon button__icon--white" size="20" />Resume session
      </div>
      <the-progress-bar
        class="the-progress-bar"
        :connected="connected"
        :attempted="attempted"
        :pending="pending"
        :total="total"
      />
    </div>
    <div class="table">
      <div v-if="!isLoading" class="prime-table">
        <CustomDatatable
          class="session-detail-table"
          @open-row-modal="openRowModal"
          v-model:rows="sessionStore.sessions[sessionId].prospects"
          :headers="sessionStore.sessions[sessionId].headers"
          @delete-contact="deleteContact"
          @sort:rows="customSort"
        />
        <row-modal
          :is-modal-open="sessionStore.selectedRowId !== null"
          :row-data="selectedRowData"
          @close-modal="closeModal"
          :headers="sessionStore.sessions[sessionId].headers"
          @saveNewInfo="saveNewInfo"
          @saveNote="saveNote"
          @deleteNote="deleteNote"
          @pinNote="pinNote"
          @saveLog="saveLog"
          @saveCallDisposition="saveCallDisposition"
          @createNewNote="createNewNote"
          @saveNewTask="saveNewTask"
          @updateTask="updateTask"
          @toggleMeetingBooked="toggleMeetingBooked"
          :meeting-booked="sessionStore.getMeetingBooked(sessionId, selectedRowData?.id)"
          :callable="false"
        />
      </div>
      <div v-else class="table__row-group--empty">
        <Loader></Loader>
      </div>
    </div>
  </div>
</template>

<script setup>
import RowModal from './components/RowModal.vue'
import Loader from './components/Loader.vue'
import PlayIcon from './components/icons/PlayIcon.vue'
import CustomDatatable from './components/Datatable--homemade.vue'
import TheProgressBar from './components/TheProgressBar.vue'
import { useSessionStore } from './stores/sessionStore'
import { usePhoneStore } from './stores/phone'
import { onMounted, ref, computed, onBeforeUnmount } from 'vue'
import axios from './services/axiosService'
import router from './router'

const props = defineProps({
  sessionId: {
    type: String,
    required: true
  }
})

function customSort() {
  const pendingItems = sessionStore.sessions[props.sessionId].prospects.filter(
    (item) => item.dialStatus === 'pending' && !item.errorStatus && !item.meetingBooked
  )
  const otherItems = sessionStore.sessions[props.sessionId].prospects.filter(
    (item) => item.dialStatus !== 'pending' || item.errorStatus || item.meetingBooked
  )

  pendingItems.sort((contactA, contactB) => {
    const result = contactA[phoneStore.sortByField.sortField].localeCompare(
      contactB[phoneStore.sortByField.sortField],
      'en',
      {
        sensitivity: 'base',
        ignorePunctuation: true
      }
    )

    return result * phoneStore.sortByField.sortOrder
  })

  sessionStore.sessions[props.sessionId].prospects = [...pendingItems, ...otherItems]
}

const connected = computed(
  () =>
    sessionStore.sessions[props.sessionId]?.prospects.filter(
      (item) => item.dialStatus === 'connected'
    ).length
)

const attemptedStatuses = ['canceled', 'no-answer', 'busy', 'voicemail', 'left-voicemail']

const attempted = computed(
  () =>
    sessionStore.sessions[props.sessionId]?.prospects.filter((item) =>
      attemptedStatuses.includes(item.dialStatus)
    ).length
)

const pending = computed(
  () =>
    sessionStore.sessions[props.sessionId]?.prospects.filter(
      (item) => item.dialStatus === 'pending' && !item.errorStatus
    ).length
)

const total = computed(
  () =>
    sessionStore.sessions[props.sessionId]?.prospects.filter(
      (item) =>
        ['connected', ...attemptedStatuses].includes(item.dialStatus) ||
        (item.dialStatus === 'pending' && !item.errorStatus)
    ).length
)

function deleteContact(row) {
  sessionStore.deleteContact(props.sessionId, row)
}

function openRowModal(row) {
  sessionStore.openRowModal(props.sessionId, row.data)
}

function saveNewInfo(rowData, header, value) {
  sessionStore.updateField(props.sessionId, rowData, header, value)
}

function saveNote(rowId, noteId, body) {
  sessionStore.updateNote(props.sessionId, rowId, noteId, body)
}

function deleteNote(rowId, noteId) {
  sessionStore.deleteNote(props.sessionId, rowId, noteId)
}

function pinNote(rowId, noteId) {
  sessionStore.pinNote(props.sessionId, rowId, noteId)
}

function saveLog(rowId, logId, body) {
  sessionStore.updateLog(props.sessionId, rowId, logId, body)
}

function saveCallDisposition(rowId, logId, callDisposition) {
  sessionStore.updateCallDisposition(props.sessionId, rowId, logId, callDisposition)
}

async function createNewNote(rowId) {
  await sessionStore.createNewNote(rowId)
  await sessionStore.fetchNotes(props.sessionId, rowId)
}

async function saveNewTask(rowId, task) {
  await sessionStore.saveNewTask(rowId, task)
  await sessionStore.fetchTasks(props.sessionId, rowId)
}

async function updateTask(row, taskId, taskField, taskValue) {
  await sessionStore.updateTask(props.sessionId, row.id, taskId, taskField, taskValue)
}

async function toggleMeetingBooked(rowId) {
  await sessionStore.toggleMeetingBooked(props.sessionId, rowId)
  if (sessionStore.getMeetingBooked(props.sessionId, rowId)) {
    const count = 200,
      defaults = {
        origin: { y: 0.7 }
      }

    function fire(particleRatio, opts) {
      confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio)
        })
      )
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55
    })

    fire(0.2, {
      spread: 60
    })

    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    })

    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    })

    fire(0.1, {
      spread: 120,
      startVelocity: 45
    })
  }
}

const selectedRowData = computed(() => {
  return sessionStore.sessions[props.sessionId].prospects.find(
    (item) => item.id === sessionStore.selectedRowId
  )
})

const sessionStore = useSessionStore()
const phoneStore = usePhoneStore()

const isLoading = ref(true)
onMounted(async () => {
  await sessionStore.fetchSessionProspectList(props.sessionId)
  sessionStore.initSessionSetting(props.sessionId)
  isLoading.value = false
})

onBeforeUnmount(() => {
  phoneStore.sortByField = null
})

function closeModal() {
  sessionStore.selectedRowId = null
}

async function reopenSession() {
  if (
    !sessionStore.currentSessionId ||
    props.sessionId.toString() !== sessionStore.currentSessionId.toString()
  ) {
    await axios.post(`/api/sessions/${props.sessionId}/reopen`)
    sessionStore.setSessionId(props.sessionId)
    await sessionStore.initCurrentSessionSetting()
    await phoneStore.initContactList()
  }
  router.push({ name: 'main' })
}
</script>

<style lang="scss">
.session-details {
  min-height: 0;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  .session-detail-table {
    table {
      border-radius: 8px;
    }
  }

  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .the-progress-bar {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
  }

  .table {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    align-items: start;

    border: 1px solid #eaecf0;
    border-radius: 8px;

    min-height: 0;
    flex: 1 1 auto;

    .prime-table {
      min-height: 0;
      flex: 1 1 auto;
      width: 100%;
    }

    .table__row-group--empty {
      margin: auto;
    }
  }

  .p-datatable-table-container {
    border-radius: 8px;
  }

  .header__button {
    padding: 8px 16px;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background: #0070ff;
    border: 1px solid #0070ff;
    color: #fff;

    &:hover {
      cursor: pointer;
      background: #006fe6;
    }
  }

  .button__icon {
    margin-right: 7px;
    stroke: #fff;
  }
}
</style>
